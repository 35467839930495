<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button variant="primary" size="sm" v-b-modal.modal-new-template>
          <BIconPlus/> New Template
        </b-button>
        <b-modal id="modal-new-template" title="Create new template" hide-footer>
            <TemplateCreateForm/>
        </b-modal>
      </template>
    </PageMenu>

    <div class="page-with-menu">

      <div v-if="!table.isLoading">
        <b-table class="bg-white" responsive hover :items="table.records" :fields="table.fields" show-empty>
          <template #empty>
            no records
          </template>
          <template #cell(options)="data">
            <div class="text-right">
              <b-link :to='{name: "emails.templates.template", params: {templateId: data.item.id}}'><BIconPencil/></b-link>
            </div>
          </template>
        </b-table>
        <PaginationRouter v-if="table.pagination.totalPages > 1" :pagination="table.pagination" :linkGen="paginationRouter"/>
      </div>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
import PageMenu from '@/common/components/PageMenu';
import {BIconPlus,BIconPencil} from 'bootstrap-vue';
import PaginationRouter from '@/common/components/Pagination.Router';
import TemplateCreateForm from './../../components/templates/Template.Form';
export default {
    components: {
      BIconPlus,BIconPencil,
      PageMenu,PaginationRouter,
      TemplateCreateForm
    },
    data(){
      return {
        page: {
            title: "Email Templates",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Emails",
                url: this.$router.resolve({name: "emails"}).href
            },
            {
              title: "Email Templates"
            }
        ],
        table: {
          isLoading: false,
          records: [],
          pagination: {
            currentPageNo: 1
          },
          fields: [
            {
              key: 'name',
              label: "Name"
            },
            {
              key: 'placeholder',
              label: "Placeholder"
            },
            {
              key: 'options',
              label: ""
            },
          ]
        }
      }
    },
    methods: {
      paginationRouter(pageNum){
        let query =  {};
        if(pageNum > 1){
            query.page = pageNum;
        }

        return this.$router.resolve({name: 'emails',query: query}).href;
      },

      getResults({pageNo},filters){
        this.table.isLoading = true;
        this.$api.get('emails/templates',{
          params: Object.assign({
            perPage: 20,
            page: pageNo,
            orderBy: "datetime",
            arrange: "desc"
          },filters || {})
        }).then(({data}) => {
          this.table.isLoading = false;
          this.table.records = data.records;
          this.table.pagination = data.pagination;
        });
      },
    },
    mounted(){
      this.getResults({pageNo: 1});
      this.$emit('set-sidebar',{active: "emails"});
    },

    watch: {
      "$route.query.page": function(pageNo){
        this.getResults({pageNo: pageNo});
      }
    },
};
</script>
